import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllLabelsInsidePreview(props) {
  const {
    ProgressLabelsInside25,
    ProgressLabelsInside50,
    ProgressLabelsInside75,
    ProgressLabelsInside100,
  } = props

  return (
    <>
      {/*<!-- Component: All progress bars with labels inside --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressLabelsInside25}
          componentName="ProgressLabelsInside25"
        >
          <div className="relative w-full">
            <label
              id="p01d-label"
              for="p01d"
              className="absolute top-0 left-0 block w-1/4 mb-0 text-xs text-center text-white"
            >
              <span className="sr-only">uploading</span> 25%
            </label>
            <progress
              aria-labelledby="p01d-label"
              id="p01d"
              max="100"
              value="25"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              25%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressLabelsInside50}
          componentName="ProgressLabelsInside50"
        >
          <div className="relative w-full">
            <label
              id="p02d-label"
              for="p02d"
              className="absolute top-0 left-0 block w-1/2 mb-0 text-xs text-center text-white"
            >
              <span className="sr-only">uploading</span> 50%
            </label>
            <progress
              aria-labelledby="p02d-label"
              id="p02d"
              max="100"
              value="50"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              50%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressLabelsInside75}
          componentName="ProgressLabelsInside75"
        >
          <div className="relative w-full">
            <label
              id="p03d-label"
              for="p03d"
              className="absolute top-0 left-0 block w-3/4 mb-0 text-xs text-center text-white"
            >
              <span className="sr-only">uploading</span> 75%
            </label>
            <progress
              aria-labelledby="p03d-label"
              id="p03d"
              max="100"
              value="75"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              75%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressLabelsInside100}
          componentName="ProgressLabelsInside100"
        >
          <div className="relative w-full">
            <label
              id="p04d-label"
              for="p04d"
              className="absolute top-0 left-0 block w-full mb-0 text-xs text-center text-white"
            >
              <span className="sr-only">uploading</span> 100%
            </label>
            <progress
              aria-labelledby="p04d-label"
              id="p04d"
              max="100"
              value="100"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              100%
            </progress>
          </div>
        </CopyComponent>
      </div>
      {/*<!-- End All progress bars with labels inside --> */}
    </>
  )
}
