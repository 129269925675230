import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllBasicPreview(props) {
  const {
    ProgressBasic25,
    ProgressBasic50,
    ProgressBasic75,
    ProgressBasic100,
  } = props

  return (
    <>
      {/*<!-- Component: All basic progress bars --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressBasic25}
          componentName="ProgressBasic25"
        >
          <progress
            aria-label="loading 25%"
            id="p01a"
            max="100"
            value="25"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            25%
          </progress>
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ProgressBasic50}
          componentName="ProgressBasic50"
        >
          <progress
            aria-label="loading 50%"
            id="p02a"
            max="100"
            value="50"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            50%
          </progress>
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ProgressBasic75}
          componentName="ProgressBasic75"
        >
          <progress
            aria-label="loading 75%"
            id="p03a"
            max="100"
            value="75"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            75%
          </progress>
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ProgressBasic100}
          componentName="ProgressBasic100"
        >
          <progress
            aria-label="loading 100%"
            id="p04a"
            max="100"
            value="100"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            100%
          </progress>
        </CopyComponent>
      </div>
      {/*<!-- End All basic progress bars --> */}
    </>
  )
}
