import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllTrailingIconPreview(props) {
  const {
    ProgressTrailingIconDanger,
    ProgressTrailingIconWarning,
    ProgressTrailingIconInfo,
    ProgressTrailingIconSuccess,
  } = props

  return (
    <>
      {/*<!-- Component: All progress bars with trailing icon --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressTrailingIconDanger}
          componentName="ProgressTrailingIconDanger"
          disableColorPicker
        >
          <div className="flex w-full gap-2">
            <label
              id="p01f-label"
              for="p01f"
              className="order-2 mb-0 text-center text-xs text-wuiPink-500 "
            >
              <span className="sr-only">uploading</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-labelledby="title-p01f desc-p01f"
              >
                <title id="title-p01f">Icon title</title>
                <desc id="desc-p01f">Some desc</desc>
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <progress
              aria-labelledby="p01f-label"
              id="p01f"
              max="100"
              value="25"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-wuiPink-500 [&::-moz-progress-bar]:bg-wuiPink-500"
            >
              25%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressTrailingIconWarning}
          componentName="ProgressTrailingIconWarning"
          disableColorPicker
        >
          <div className="flex w-full gap-2">
            <label
              id="p02f-label"
              for="p02f"
              className="order-2 mb-0 text-center text-xs text-wuiAmber-400 "
            >
              <span className="sr-only">uploading</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-labelledby="title-p02f desc-p02f"
              >
                <title id="title-p02f">Icon title</title>
                <desc id="desc-p02f">Some desc</desc>
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <progress
              aria-labelledby="p02f-label"
              id="p02f"
              max="100"
              value="50"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-wuiAmber-400 [&::-moz-progress-bar]:bg-wuiAmber-400"
            >
              50%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressTrailingIconInfo}
          componentName="ProgressTrailingIconInfo"
          disableColorPicker
        >
          <div className="flex w-full gap-2">
            <label
              id="p03f-label"
              for="p03f"
              className="order-2 mb-0 text-center text-xs text-wuiCyan-500 "
            >
              <span className="sr-only">uploading</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-labelledby="title-p03f desc-p03f"
              >
                <title id="title-p03f">Icon title</title>
                <desc id="desc-p03f">Some desc</desc>
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <progress
              aria-labelledby="p03f-label"
              id="p03f"
              max="100"
              value="75"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-wuiCyan-500 [&::-moz-progress-bar]:bg-wuiCyan-500"
            >
              75%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressTrailingIconSuccess}
          componentName="ProgressTrailingIconSuccess"
          disableColorPicker
        >
          <div className="flex w-full gap-2">
            <label
              id="p04f-label"
              for="p04f"
              className="order-2 mb-0 text-center text-xs text-wuiEmerald-500"
            >
              <span className="sr-only">uploading</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-labelledby="title-p04f desc-p04f"
              >
                <title id="title-p04f">Icon title</title>
                <desc id="desc-p04f">Some desc</desc>
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <progress
              aria-labelledby="p04f-label"
              id="p04f"
              max="100"
              value="100"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-wuiEmerald-500 [&::-moz-progress-bar]:bg-wuiEmerald-500"
            >
              100%
            </progress>
          </div>
        </CopyComponent>
      </div>
      {/*<!-- End All progress bars with trailing icon --> */}
    </>
  )
}
