import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllSizeVariationPreview(props) {
  const {
    ProgressSizeVariationsXs,
    ProgressSizeVariationsSm,
    ProgressSizeVariationsBase,
    ProgressSizeVariationsLg,
  } = props

  return (
    <>
      {/*<!-- Component: All size variations progress bars  --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressSizeVariationsXs}
          componentName="ProgressSizeVariationsXs"
        >
          {/*  <!-- xs --> */}
          <progress
            aria-label="loading 20%"
            id="p01b"
            max="100"
            value="20"
            className="h-1 w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            20%
          </progress>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressSizeVariationsSm}
          componentName="ProgressSizeVariationsSm"
        >
          {/*  <!-- sm --> */}
          <progress
            aria-label="loading 40%"
            id="p02b"
            max="100"
            value="40"
            className="h-2 w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            40%
          </progress>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressSizeVariationsBase}
          componentName="ProgressSizeVariationsBase"
        >
          {/*  <!-- base --> */}
          <progress
            aria-label="loading 60%"
            id="p03b"
            max="100"
            value="60"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            60%
          </progress>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressSizeVariationsLg}
          componentName="ProgressSizeVariationsLg"
        >
          {/*  <!-- lg --> */}
          <progress
            aria-label="loading 80%"
            id="p04b"
            max="100"
            value="80"
            className="h-6 w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            80%
          </progress>
        </CopyComponent>
      </div>
      {/*<!-- End All size variations progress bars --> */}
    </>
  )
}
