import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllColoredPreview(props) {
  const {
    ProgressColoredCyan,
    ProgressColoredIndigo,
    ProgressColoredPurple,
    ProgressColoredEmerald,
  } = props

  return (
    <>
      {/*<!-- Component: All colored progress bars --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressColoredCyan}
          componentName="ProgressColoredCyan"
        >
          <progress
            aria-label="loading 40%"
            id="p02g"
            max="100"
            value="40"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-cyan-500 [&::-moz-progress-bar]:bg-cyan-500"
          >
            40%
          </progress>
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ProgressColoredIndigo}
          componentName="ProgressColoredIndigo"
        >
          <progress
            aria-label="loading 60%"
            id="p03g"
            max="100"
            value="60"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-indigo-500 [&::-moz-progress-bar]:bg-indigo-500"
          >
            60%
          </progress>
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ProgressColoredPurple}
          componentName="ProgressColoredPurple"
        >
          <progress
            aria-label="loading 80%"
            id="p04g"
            max="100"
            value="80"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-purple-500 [&::-moz-progress-bar]:bg-purple-500"
          >
            80%
          </progress>
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ProgressColoredEmerald}
          componentName="ProgressColoredEmerald"
        >
          <progress
            aria-label="loading 100%"
            id="p05g"
            max="100"
            value="100"
            className="w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-gradient-to-r [&::-webkit-progress-value]:from-emerald-500 [&::-webkit-progress-value]:via-cyan-500 [&::-webkit-progress-value]:to-purple-500  [&::-moz-progress-bar]:bg-gradient-to-r [&::-moz-progress-bar]:from-emerald-500 [&::-moz-progress-bar]:via-cyan-500 [&::-moz-progress-bar]:to-purple-500"
          >
            100%
          </progress>
        </CopyComponent>
      </div>
      {/*<!-- End All colored progress bars --> */}
    </>
  )
}
