import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO
import ogImage from "../../../images/seo/progress-bars/progress-bars.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
import ProgressBarHeroComponent from "../../../library/components/progress/react/_preview/ProgressBarHeroComponent"

// Preview
import ProgressAllBasicPreview from "../../../library/components/progress/react/_preview/basic"
import ProgressAllLabelsAbovePreview from "../../../library/components/progress/react/_preview/labels_above"
import ProgressAllLabelsInsidePreview from "../../../library/components/progress/react/_preview/labels_inside"
import ProgressAllTrailingLabelPreview from "../../../library/components/progress/react/_preview/trailing_label"
import ProgressAllTrailingIconPreview from "../../../library/components/progress/react/_preview/trailing_icon"
import ProgressAllColoredPreview from "../../../library/components/progress/react/_preview/colored"
import ProgressAllSizeVariationPreview from "../../../library/components/progress/react/_preview/size_variation"

// Basic
const ProgressBasic25Jsx = require("!!raw-loader!../../../library/components/progress/react/basic/25.jsx")
const ProgressBasic25HTML = require("!!raw-loader!../../../library/components/progress/html/basic/25.html")

const ProgressBasic50Jsx = require("!!raw-loader!../../../library/components/progress/react/basic/50.jsx")
const ProgressBasic50HTML = require("!!raw-loader!../../../library/components/progress/html/basic/50.html")

const ProgressBasic75Jsx = require("!!raw-loader!../../../library/components/progress/react/basic/75.jsx")
const ProgressBasic75HTML = require("!!raw-loader!../../../library/components/progress/html/basic/75.html")

const ProgressBasic100Jsx = require("!!raw-loader!../../../library/components/progress/react/basic/100.jsx")
const ProgressBasic100HTML = require("!!raw-loader!../../../library/components/progress/html/basic/100.html")

// Labels Above
const ProgressLabelsAboveLoadingStartJsx = require("!!raw-loader!../../../library/components/progress/react/labels-above/loading_start.jsx")
const ProgressLabelsAboveLoadingStartHTML = require("!!raw-loader!../../../library/components/progress/html/labels-above/loading_start.html")

const ProgressLabelsAboveLoadingCenterJsx = require("!!raw-loader!../../../library/components/progress/react/labels-above/loading_center.jsx")
const ProgressLabelsAboveLoadingCenterHTML = require("!!raw-loader!../../../library/components/progress/html/labels-above/loading_center.html")

const ProgressLabelsAboveTimeRemainingJsx = require("!!raw-loader!../../../library/components/progress/react/labels-above/time_remaining.jsx")
const ProgressLabelsAboveTimeRemainingHTML = require("!!raw-loader!../../../library/components/progress/html/labels-above/time_remaining.html")

const ProgressLabelsAboveTimeRemainingLoadingJsx = require("!!raw-loader!../../../library/components/progress/react/labels-above/loading_time_remaining.jsx")
const ProgressLabelsAboveTimeRemainingLoadingHTML = require("!!raw-loader!../../../library/components/progress/html/labels-above/loading_time_remaining.html")

// Labels Inside
const ProgressLabelsInside25Jsx = require("!!raw-loader!../../../library/components/progress/react/labels-inside/25.jsx")
const ProgressLabelsInside25HTML = require("!!raw-loader!../../../library/components/progress/html/labels-inside/25.html")

const ProgressLabelsInside50Jsx = require("!!raw-loader!../../../library/components/progress/react/labels-inside/50.jsx")
const ProgressLabelsInside50HTML = require("!!raw-loader!../../../library/components/progress/html/labels-inside/50.html")

const ProgressLabelsInside75Jsx = require("!!raw-loader!../../../library/components/progress/react/labels-inside/75.jsx")
const ProgressLabelsInside75HTML = require("!!raw-loader!../../../library/components/progress/html/labels-inside/75.html")

const ProgressLabelsInside100Jsx = require("!!raw-loader!../../../library/components/progress/react/labels-inside/100.jsx")
const ProgressLabelsInside100HTML = require("!!raw-loader!../../../library/components/progress/html/labels-inside/100.html")

// Trailing Label
const ProgressTrailingLabel25Jsx = require("!!raw-loader!../../../library/components/progress/react/trailing-label/25.jsx")
const ProgressTrailingLabel25HTML = require("!!raw-loader!../../../library/components/progress/html/trailing-label/25.html")

const ProgressTrailingLabel50Jsx = require("!!raw-loader!../../../library/components/progress/react/trailing-label/50.jsx")
const ProgressTrailingLabel50HTML = require("!!raw-loader!../../../library/components/progress/html/trailing-label/50.html")

const ProgressTrailingLabel75Jsx = require("!!raw-loader!../../../library/components/progress/react/trailing-label/75.jsx")
const ProgressTrailingLabel75HTML = require("!!raw-loader!../../../library/components/progress/html/trailing-label/75.html")

const ProgressTrailingLabel100Jsx = require("!!raw-loader!../../../library/components/progress/react/trailing-label/100.jsx")
const ProgressTrailingLabel100HTML = require("!!raw-loader!../../../library/components/progress/html/trailing-label/100.html")

// Trailing Icon
const ProgressTrailingIconDangerJsx = require("!!raw-loader!../../../library/components/progress/react/trailing-icon/danger.jsx")
const ProgressTrailingIconDangerHTML = require("!!raw-loader!../../../library/components/progress/html/trailing-icon/danger.html")

const ProgressTrailingIconWarningJsx = require("!!raw-loader!../../../library/components/progress/react/trailing-icon/warning.jsx")
const ProgressTrailingIconWarningHTML = require("!!raw-loader!../../../library/components/progress/html/trailing-icon/warning.html")

const ProgressTrailingIconInfoJsx = require("!!raw-loader!../../../library/components/progress/react/trailing-icon/info.jsx")
const ProgressTrailingIconInfoHTML = require("!!raw-loader!../../../library/components/progress/html/trailing-icon/info.html")

const ProgressTrailingIconSuccessJsx = require("!!raw-loader!../../../library/components/progress/react/trailing-icon/success.jsx")
const ProgressTrailingIconSuccessHTML = require("!!raw-loader!../../../library/components/progress/html/trailing-icon/success.html")

// Colored
const ProgressColoredCyanJsx = require("!!raw-loader!../../../library/components/progress/react/color/cyan.jsx")
const ProgressColoredCyanHTML = require("!!raw-loader!../../../library/components/progress/html/color/cyan.html")

const ProgressColoredIndigoJsx = require("!!raw-loader!../../../library/components/progress/react/color/indigo.jsx")
const ProgressColoredIndigoHTML = require("!!raw-loader!../../../library/components/progress/html/color/indigo.html")

const ProgressColoredPurpleJsx = require("!!raw-loader!../../../library/components/progress/react/color/purple.jsx")
const ProgressColoredPurpleHTML = require("!!raw-loader!../../../library/components/progress/html/color/purple.html")

const ProgressColoredEmeraldJsx = require("!!raw-loader!../../../library/components/progress/react/color/gradient.jsx")
const ProgressColoredEmeraldHTML = require("!!raw-loader!../../../library/components/progress/html/color/gradient.html")

// Size Variation
const ProgressSizeVariationsXsJsx = require("!!raw-loader!../../../library/components/progress/react/size-variations/xs.jsx")
const ProgressSizeVariationsXsHTML = require("!!raw-loader!../../../library/components/progress/html/size-variations/xs.html")

const ProgressSizeVariationsSmJsx = require("!!raw-loader!../../../library/components/progress/react/size-variations/sm.jsx")
const ProgressSizeVariationsSmHTML = require("!!raw-loader!../../../library/components/progress/html/size-variations/sm.html")

const ProgressSizeVariationsBaseJsx = require("!!raw-loader!../../../library/components/progress/react/size-variations/base.jsx")
const ProgressSizeVariationsBaseHTML = require("!!raw-loader!../../../library/components/progress/html/size-variations/base.html")

const ProgressSizeVariationsLgJsx = require("!!raw-loader!../../../library/components/progress/react/size-variations/lg.jsx")
const ProgressSizeVariationsLgHTML = require("!!raw-loader!../../../library/components/progress/html/size-variations/lg.html")

export default function ProgressBarsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "ProgressAllBasic",
      title: "Basic progress bars",
      active_tab: 1,
    },
    {
      component_name: "ProgressAllLabelsAbove",
      title: "With labels above",
      active_tab: 1,
    },
    {
      component_name: "ProgressAllLabelsInside",
      title: "With labels inside",
      active_tab: 1,
    },
    {
      component_name: "ProgressAllTrailingLabel",
      title: "With trailing labels",
      active_tab: 1,
    },
    {
      component_name: "ProgressAllTrailingIcon",
      title: "With trailing icons",
      active_tab: 1,
    },

    {
      component_name: "ProgressAllColored",
      title: "Colored",
      active_tab: 1,
    },
    {
      component_name: "ProgressAllSizeVariation",
      title: "Different sizes",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Progress Bars - WindUI Component Library"
        ogtitle="Tailwind CSS Progress Bars - WindUI Component Library"
        description="Progress bar components are progress indicators inform users about the status of ongoing processes. Built with Tailwind CSS by WindUI."
        ogdescription="Progress bar components are progress indicators inform users about the status of ongoing processes. Built with Tailwind CSS by WindUI."
        url="components/progress-bars/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Progress Bars, Progress Bars, Progress Bar Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Progress Bars</h1>
          <p>
            Progress indicators inform users about the status of ongoing
            processes.
          </p>

          {/* Hero Preview Section */}

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <ProgressBarHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="ProgressAllBasic">Basic</h3>

          <PreviewBlock
            id="ProgressAllBasic"
            HtmlComponent={ProgressBasic25HTML.default}
            JsxComponent={ProgressBasic25Jsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllBasicPreview
                ProgressBasic25={
                  activeTabs[0].active_tab === 1
                    ? ProgressBasic25HTML.default
                    : ProgressBasic25Jsx.default
                }
                ProgressBasic50={
                  activeTabs[0].active_tab === 1
                    ? ProgressBasic50HTML.default
                    : ProgressBasic50Jsx.default
                }
                ProgressBasic75={
                  activeTabs[0].active_tab === 1
                    ? ProgressBasic75HTML.default
                    : ProgressBasic75Jsx.default
                }
                ProgressBasic100={
                  activeTabs[0].active_tab === 1
                    ? ProgressBasic100HTML.default
                    : ProgressBasic100Jsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ProgressAllLabelsAbove">Progress bars with labels above</h3>

          <PreviewBlock
            id="ProgressAllLabelsAbove"
            HtmlComponent={ProgressLabelsAboveLoadingStartHTML.default}
            JsxComponent={ProgressLabelsAboveLoadingStartJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllLabelsAbovePreview
                ProgressLabelsAboveLoadingStart={
                  activeTabs[1].active_tab === 1
                    ? ProgressLabelsAboveLoadingStartHTML.default
                    : ProgressLabelsAboveLoadingStartJsx.default
                }
                ProgressLabelsAboveLoadingCenter={
                  activeTabs[1].active_tab === 1
                    ? ProgressLabelsAboveLoadingCenterHTML.default
                    : ProgressLabelsAboveLoadingCenterJsx.default
                }
                ProgressLabelsAboveTimeRemaining={
                  activeTabs[1].active_tab === 1
                    ? ProgressLabelsAboveTimeRemainingHTML.default
                    : ProgressLabelsAboveTimeRemainingJsx.default
                }
                ProgressLabelsAboveTimeRemainingLoading={
                  activeTabs[1].active_tab === 1
                    ? ProgressLabelsAboveTimeRemainingLoadingHTML.default
                    : ProgressLabelsAboveTimeRemainingLoadingJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ProgressAllLabelsInside">Progress bars with labels inside</h3>

          <PreviewBlock
            id="ProgressAllLabelsInside"
            HtmlComponent={ProgressLabelsInside25HTML.default}
            JsxComponent={ProgressLabelsInside25Jsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllLabelsInsidePreview
                ProgressLabelsInside25={
                  activeTabs[2].active_tab === 1
                    ? ProgressLabelsInside25HTML.default
                    : ProgressLabelsInside25Jsx.default
                }
                ProgressLabelsInside50={
                  activeTabs[2].active_tab === 1
                    ? ProgressLabelsInside50HTML.default
                    : ProgressLabelsInside50Jsx.default
                }
                ProgressLabelsInside75={
                  activeTabs[2].active_tab === 1
                    ? ProgressLabelsInside75HTML.default
                    : ProgressLabelsInside75Jsx.default
                }
                ProgressLabelsInside100={
                  activeTabs[2].active_tab === 1
                    ? ProgressLabelsInside100HTML.default
                    : ProgressLabelsInside100Jsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ProgressAllTrailingLabel">
            Progress bars with trailing labels
          </h3>

          <PreviewBlock
            id="ProgressAllTrailingLabel"
            HtmlComponent={ProgressTrailingLabel25HTML.default}
            JsxComponent={ProgressTrailingLabel25Jsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllTrailingLabelPreview
                ProgressTrailingLabel25={
                  activeTabs[3].active_tab === 1
                    ? ProgressTrailingLabel25HTML.default
                    : ProgressTrailingLabel25Jsx.default
                }
                ProgressTrailingLabel50={
                  activeTabs[3].active_tab === 1
                    ? ProgressTrailingLabel50HTML.default
                    : ProgressTrailingLabel50Jsx.default
                }
                ProgressTrailingLabel75={
                  activeTabs[3].active_tab === 1
                    ? ProgressTrailingLabel75HTML.default
                    : ProgressTrailingLabel75Jsx.default
                }
                ProgressTrailingLabel100={
                  activeTabs[3].active_tab === 1
                    ? ProgressTrailingLabel100HTML.default
                    : ProgressTrailingLabel100Jsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ProgressAllTrailingIcon">
            Progress bars with trailing icons
          </h3>

          <PreviewBlock
            id="ProgressAllTrailingIcon"
            HtmlComponent={ProgressTrailingIconDangerHTML.default}
            JsxComponent={ProgressTrailingIconDangerJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllTrailingIconPreview
                ProgressTrailingIconDanger={
                  activeTabs[4].active_tab === 1
                    ? ProgressTrailingIconDangerHTML.default
                    : ProgressTrailingIconDangerJsx.default
                }
                ProgressTrailingIconWarning={
                  activeTabs[4].active_tab === 1
                    ? ProgressTrailingIconWarningHTML.default
                    : ProgressTrailingIconWarningJsx.default
                }
                ProgressTrailingIconInfo={
                  activeTabs[4].active_tab === 1
                    ? ProgressTrailingIconInfoHTML.default
                    : ProgressTrailingIconInfoJsx.default
                }
                ProgressTrailingIconSuccess={
                  activeTabs[4].active_tab === 1
                    ? ProgressTrailingIconSuccessHTML.default
                    : ProgressTrailingIconSuccessJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ProgressAllColored">Colored progress bars</h3>

          <PreviewBlock
            id="ProgressAllColored"
            HtmlComponent={ProgressColoredCyanHTML.default}
            JsxComponent={ProgressColoredCyanJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllColoredPreview
                ProgressColoredCyan={
                  activeTabs[5].active_tab === 1
                    ? ProgressColoredCyanHTML.default
                    : ProgressColoredCyanJsx.default
                }
                ProgressColoredIndigo={
                  activeTabs[5].active_tab === 1
                    ? ProgressColoredIndigoHTML.default
                    : ProgressColoredIndigoJsx.default
                }
                ProgressColoredPurple={
                  activeTabs[5].active_tab === 1
                    ? ProgressColoredPurpleHTML.default
                    : ProgressColoredPurpleJsx.default
                }
                ProgressColoredEmerald={
                  activeTabs[5].active_tab === 1
                    ? ProgressColoredEmeraldHTML.default
                    : ProgressColoredEmeraldJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ProgressAllSizeVariation">Size Variation</h3>

          <PreviewBlock
            id="ProgressAllSizeVariation"
            HtmlComponent={ProgressSizeVariationsXsHTML.default}
            JsxComponent={ProgressSizeVariationsXsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ProgressAllSizeVariationPreview
                ProgressSizeVariationsXs={
                  activeTabs[6].active_tab === 1
                    ? ProgressSizeVariationsXsHTML.default
                    : ProgressSizeVariationsXsJsx.default
                }
                ProgressSizeVariationsSm={
                  activeTabs[6].active_tab === 1
                    ? ProgressSizeVariationsSmHTML.default
                    : ProgressSizeVariationsSmJsx.default
                }
                ProgressSizeVariationsBase={
                  activeTabs[6].active_tab === 1
                    ? ProgressSizeVariationsBaseHTML.default
                    : ProgressSizeVariationsBaseJsx.default
                }
                ProgressSizeVariationsLg={
                  activeTabs[6].active_tab === 1
                    ? ProgressSizeVariationsLgHTML.default
                    : ProgressSizeVariationsLgJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Progress indicators inform users about the status of ongoing
            processes, such as loading an app, submitting a form, or saving
            updates. They communicate an app’s state and indicate available
            actions, such as whether users can navigate away from the current
            screen.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Progress bars using the native <code>&lt;progress&gt;</code> HTML
            element should include the following attributes:
          </p>
          <ul>
            <li>
              <strong>max: </strong> corresponds to the maximum value of the
              progress.
            </li>
            <li>
              <strong>value: </strong> corresponds to the active value of the
              progress.
            </li>
          </ul>
          <p>The progress component can contain the following elements:</p>
          <ul>
            <li>
              <strong>Progress bar:</strong> the bar has a 1rem (16px) height
              for the base size, 1.5rem (24px) for the large size, 0.5rem (8px)
              for the small size and 0.25rem (4px) height in the extra small
              size.
            </li>
            <li>
              <strong>Label: </strong> 0.75rem (12px) font size and can be
              positioned above or inside (on base and large sizes) the progress
              bar.
            </li>
            <li>
              <strong>Icon: </strong> 1rem (16px) width and height.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Since the native HTML <code>&lt;progress&gt;</code> is utilized no
            extra roles are required. In most cases, you should provide an
            accessible label when using this element.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
