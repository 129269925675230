import React from "react"

export default function ProgressBarHeroComponent() {
  return (
    <>
      {/*<!-- Component: All progress bars with labels above --> */}
      <div className="flex flex-col gap-8">
        <div className="relative w-full">
          <label
            id="p01c-label"
            for="p01c"
            className="mb-1 block text-xs text-slate-500"
          >
            Loading..
          </label>
          <progress
            aria-labelledby="p01c-label"
            id="p01c"
            max="100"
            value="20"
            className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            20%
          </progress>
        </div>
        <div className="relative w-full">
          <label
            id="p02c-label"
            for="p02c"
            className="mb-1 block text-center text-xs text-slate-500"
          >
            Loading..
          </label>
          <progress
            aria-labelledby="p02c-label"
            id="p02c"
            max="100"
            value="40"
            className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            40%
          </progress>
        </div>
        <div className="relative w-full">
          <label
            id="p03c-label"
            for="p03c"
            className="mb-1 block text-right text-xs text-slate-400"
          >
            <span className="sr-only">uploading</span> 2 mins remaining
          </label>
          <progress
            aria-labelledby="p03c-label"
            id="p03c"
            max="100"
            value="60"
            className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            60%
          </progress>
        </div>
        <div className="relative w-full">
          <label
            id="p04c-label"
            for="p04c"
            className="mb-1 flex text-xs text-slate-500"
          >
            <span className="flex-1">Loading..</span>
            <span className="text-slate-400">2 mins remaining</span>
          </label>
          <progress
            aria-labelledby="p04c-label"
            id="p04c"
            max="100"
            value="80"
            className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
          >
            80%
          </progress>
        </div>
      </div>
      {/*<!-- End All progress bars with labels above --> */}
    </>
  )
}
