import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllLabelsAbovePreview(props) {
  const {
    ProgressLabelsAboveLoadingStart,
    ProgressLabelsAboveLoadingCenter,
    ProgressLabelsAboveTimeRemaining,
    ProgressLabelsAboveTimeRemainingLoading,
  } = props

  return (
    <>
      {/*<!-- Component: All progress bars with labels above --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressLabelsAboveLoadingStart}
          componentName="ProgressLabelsAboveLoadingStart"
        >
          <div className="relative w-full">
            <label
              id="p01c-label"
              for="p01c"
              className="block mb-1 text-xs text-slate-500"
            >
              Loading..
            </label>
            <progress
              aria-labelledby="p01c-label"
              id="p01c"
              max="100"
              value="20"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              20%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressLabelsAboveLoadingCenter}
          componentName="ProgressLabelsAboveLoadingCenter"
        >
          <div className="relative w-full">
            <label
              id="p02c-label"
              for="p02c"
              className="block mb-1 text-xs text-center text-slate-500"
            >
              Loading..
            </label>
            <progress
              aria-labelledby="p02c-label"
              id="p02c"
              max="100"
              value="40"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              40%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressLabelsAboveTimeRemaining}
          componentName="ProgressLabelsAboveTimeRemaining"
        >
          <div className="relative w-full">
            <label
              id="p03c-label"
              for="p03c"
              className="block mb-1 text-xs text-right text-slate-400"
            >
              <span className="sr-only">uploading</span> 2 mins remaining
            </label>
            <progress
              aria-labelledby="p03c-label"
              id="p03c"
              max="100"
              value="60"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              60%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressLabelsAboveTimeRemainingLoading}
          componentName="ProgressLabelsAboveTimeRemainingLoading"
        >
          <div className="relative w-full">
            <label
              id="p04c-label"
              for="p04c"
              className="flex mb-1 text-xs text-slate-500"
            >
              <span className="flex-1">Loading..</span>
              <span className="text-slate-400">2 mins remaining</span>
            </label>
            <progress
              aria-labelledby="p04c-label"
              id="p04c"
              max="100"
              value="80"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              80%
            </progress>
          </div>
        </CopyComponent>
      </div>
      {/*<!-- End All progress bars with labels above --> */}
    </>
  )
}
