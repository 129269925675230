import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ProgressAllTrailingLabelPreview(props) {
  const {
    ProgressTrailingLabel25,
    ProgressTrailingLabel50,
    ProgressTrailingLabel75,
    ProgressTrailingLabel100,
  } = props
  return (
    <>
      {/*<!-- Component:  All progress bars with trailing label --> */}
      <div className="flex flex-col gap-8">
        <CopyComponent
          copyToClipboardCode={ProgressTrailingLabel25}
          componentName="ProgressTrailingLabel25"
        >
          <div className="flex w-full gap-2">
            <label
              id="p01e-label"
              for="p01e"
              className="order-2 mb-0 text-xs text-center text-slate-500 "
            >
              <span className="sr-only">uploading</span> 25%
            </label>
            <progress
              aria-labelledby="p01e-label"
              id="p01e"
              max="100"
              value="25"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              25%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressTrailingLabel50}
          componentName="ProgressTrailingLabel50"
        >
          <div className="flex w-full gap-2">
            <label
              id="p02e-label"
              for="p02e"
              className="order-2 mb-0 text-xs text-center text-slate-500 "
            >
              <span className="sr-only">uploading</span> 50%
            </label>
            <progress
              aria-labelledby="p02e-label"
              id="p02e"
              max="100"
              value="50"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              50%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressTrailingLabel75}
          componentName="ProgressTrailingLabel75"
        >
          <div className="flex w-full gap-2">
            <label
              id="p03e-label"
              for="p03e"
              className="order-2 mb-0 text-xs text-center text-slate-500 "
            >
              <span className="sr-only">uploading</span> 75%
            </label>
            <progress
              aria-labelledby="p03e-label"
              id="p03e"
              max="100"
              value="75"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              75%
            </progress>
          </div>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={ProgressTrailingLabel100}
          componentName="ProgressTrailingLabel100"
        >
          <div className="flex w-full gap-2">
            <label
              id="p04e-label"
              for="p04e"
              className="order-2 mb-0 text-xs text-center text-slate-500 "
            >
              <span className="sr-only">uploading</span> 100%
            </label>
            <progress
              aria-labelledby="p04e-label"
              id="p04e"
              max="100"
              value="100"
              className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-emerald-500 [&::-moz-progress-bar]:bg-emerald-500"
            >
              100%
            </progress>
          </div>
        </CopyComponent>
      </div>
      {/*<!-- End  All progress bars with trailing label --> */}
    </>
  )
}
